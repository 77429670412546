@import "../../styles/core/variables";
@import "../../styles/core/mixins";

.header {
    position: relative;
    margin: 0 auto;
}

.switch_theme {
    top: 10px;
    right: 80px;
    position: absolute;
}

.switch_theme_mobile {
    display: none
}

.author_line {
    @include center-space-between();
    max-height: 450px;
    padding: 10px 100px;
    overflow: hidden;
    border-bottom: 1px solid var(--f-header-border-color);
    background-color: var(--f-doc-background-color);


    & .img {
        width: 100%;
        // height: auto;
        object-fit: contain;
        object-position: center;
    }

    & .author_name {
        margin: 0;
        font-size: 64px;
        line-height: 1.1;

        // &:hover {
        //     background-color: #fff;
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: transparent;
        //     animation-name: animateText;
        //     animation-duration: 3s;
        // }
    }

    & p {
        margin: 0;
    }

    & nav {
        width: 100%;
        @include center-space-between();

        & a {
            display: block;
            padding: 0 20px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.header_btn {
    margin-right: 30px;
}

.image_line {
    height: 500px;
    padding: 0;

    background-image: url('../../../public/image/author_page/main1.png');
    background-image: url('../../../public/image/author_page/main1.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 -160px;
}

@include media-max($bp-large) {
    .switch_theme {
        top: -10px;
        right: 20px;
    }

    .author_line {
        padding: 10px 50px;

        & .author_name {
            font-size: 40px;
        }
    }
}

@include media-max($bp-medium) {
    
    .header {
        padding: 0;
    }

    .author_line {
        padding: 10px 0;

        & nav {
            & a {
                padding: 0 10px;
            }
        }
    }

    .image_line {
        padding: 0;
    }
}

@include media-max($bp-small) {
    .switch_theme {
        display: none;
    }

    .switch_theme_mobile {
        display: block;
    }
    .author_line {
        padding: 10px;

        & nav {
            align-items: start;

            & a {
                padding: 10px 0;
            }
        }

        & .btn:first-child {
            margin-right: 0;
        }
    }

    .author_line {
        &:nth-child(2) {
            padding-bottom: 10px;
        }

        & .author_name {
            font-size: 24px;
        }


    }

    .image_line {
        display: none;
    }
}