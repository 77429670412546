@import "../../styles/core/variables";
@import "../../styles/core/mixins";

.f_hamburger_switch {
    margin-right: 8px;
    height: 100%;
    padding: 10px 8px 8px 10px !important;
    @include center-v-h;
    cursor: pointer;

    .cont {
        @include fluid-items(8px, 0, 0);
        position: relative;
        width: 20px;
        height: 20px;
    }

    .bx {
        width: 8px;
        height: 8px;
        border: 2px solid var(--f-doc-color);
    }

    .bx1 {
         border-radius: 3px 3px 4px 3px;
    }

    .bx2 {
        border-radius: 3px 3px 3px 4px;
    }

    .bx3 {
       border-radius: 3px 3px 4px 3px;
    }

    .bx4 {
       border-radius: 4px 3px 3px 3px;
    }
}

@include media-max($bp-small) {
    .f-hamburger-switch {
        margin-right: 2px;
        padding: 7px 5px 5px 7px !important;

        .cont {
            width: 18px;
            height: 18px;
        }

        .bx {
            width: 7px;
            height: 7px;
        }
    }
}